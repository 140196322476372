<template>
  <div>
    <template v-if="!loadingInitial">
      <XyzTransitionGroup
        appear
        xyz="fade down-4 stagger-2 out-stagger-2"
      >
        <v-card :key="1">
          <v-row
            class="mx-2 pt-2 align-center"
          >
            <v-col>
              <!-- Job Type Tabs -->
              <v-tabs
                v-model="activeTab"
                class="px-0"
                show-arrows
                style="box-shadow: none !important;"
              >
                <v-tab
                  v-for="(type) in jobTypeList"
                  :key="type.id"
                  class="text-capitalize overflow-hidden"
                  style="width: 220px"
                  @click="state.jobFilter.value.types = [type.id]; switchJobType(type.id)"
                >
                  <v-hover
                    #default="{ hover: isHovered }"
                  >
                    <v-row>
                      <v-col
                        cols="10"
                        class="pe-0"
                      >
                        <div
                          class="d-flex justify-space-between align-center"
                        >
                          <v-badge
                            dot
                            inline
                            bottom
                            overlap
                            class="mt-1"
                            :color="type.color"
                          />
                          <span
                            class="text-truncate mx-4"
                            style="max-width: 150px"
                          >
                            {{ type.name }}
                          </span>
                          <v-icon
                            v-if="!type.is_public"
                            size="14"
                            class="me-1"
                          >
                            {{ icons.mdiLockOutline }}
                          </v-icon>
                          <v-icon
                            v-if="type.archived_at"
                            size="14"
                          >
                            {{ icons.mdiArchive }}
                          </v-icon>
                          <span />
                        </div>
                      </v-col>
                      <v-col
                        cols="2"
                        class="pb-1"
                      >
                        <v-menu
                          v-if="folderPermissionGuard(type, 1)"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                v-show="isHovered"
                                v-bind="attrs"
                                size="18"
                                v-on="on"
                              >
                                {{ icons.mdiDotsVertical }}
                              </v-icon>
                            </v-slide-x-reverse-transition>
                          </template>
                          <v-list>
                            <v-list-item
                              @click="$refs.typeForm.update(type)"
                            >
                              <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="$refs.editFolderPermissionForm.show(type)"
                            >
                              <v-list-item-title>Sharing & Permission</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              class="warning--text"
                              :disabled="!folderPermissionGuard(type, 0)"
                              @click="!type.archived_at ? confirmArchiveJobType(type) : confirmUnarchiveJobType(type)"
                            >
                              <v-list-item-title>{{ !type.archived_at ? 'Archive' : 'Unarchive' }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              class="error--text"
                              :disabled="!folderPermissionGuard(type, 0)"
                              @click="confirmDeleteJobType(type)"
                            >
                              <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-hover>
                </v-tab>
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      small
                      class="align-self-center ms-4"
                      :style="{'border-top-right-radius':'0%', 'border-bottom-right-radius':'0%'}"
                      v-bind="attrs"
                      v-on="on"
                      @click="$refs.typeForm.add(id)"
                    >
                      <v-icon
                        class="light--text"
                        size="20"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Buat Job Type Baru</span>
                </v-tooltip>
                <v-menu
                  v-model="isJobTypeFilterOpen"
                  :close-on-content-click="false"
                  offset-y
                  nudge-bottom="8px"
                  nudge-left="20px"
                  transition="slide-y-reverse-transition"
                >
                  <template v-slot:activator="menu">
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="tooltip">
                        <v-badge
                          :value="jobTypeFilterCount > 0"
                          color="primary"
                          :content="jobTypeFilterCount"
                          overlap
                          class="align-self-center me-4"
                        >
                          <v-btn
                            color="primary"
                            small
                            outlined
                            :style="{'border-top-left-radius':'0%', 'border-bottom-left-radius':'0%'}"
                            v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                            v-on="{ ...menu.on, ...tooltip.on }"
                          >
                            <v-icon
                              class="light--text"
                              size="20"
                            >
                              {{ icons.mdiMenuDown }}
                            </v-icon>
                          </v-btn>
                        </v-badge>
                      </template>
                      <span>Search & Filter</span>
                    </v-tooltip>
                  </template>
                  <JobTypeFilter @onSearchJobType="getJobTypeData()" />
                </v-menu>
              </v-tabs>
            </v-col>
            <v-col
              cols="auto"
              class="ms-auto d-flex align-center"
            >
              <v-menu
                :close-on-content-click="false"
                offset-y
                nudge-bottom="8px"
                nudge-left="20px"
                transition="slide-y-reverse-transition"
              >
                <template v-slot:activator="menu">
                  <v-tooltip bottom>
                    <template v-slot:activator="tooltip">
                      <v-icon
                        size="26"
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop
                      >
                        {{ icons.mdiChevronDown }}
                      </v-icon>
                    </template>
                    <span>Cari job berdasarkan...</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-text>
                    Cari Berdasarkan
                    <div
                      v-for="(field, index) in state.jobFilter.value.search_field"
                      :key="index"
                    >
                      <v-switch
                        v-model="field.value"
                        inset
                        flat
                        dense
                        hide-details
                        :label="field.label"
                        @change="fetchJobs()"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
              <input-icon-toggle
                v-model="state.jobFilter.value.search"
                class="ms-2 me-3"
                :icon="icons.mdiMagnify"
                placeholder="Cari job"
                @close="state.jobFilter.value.search = ''; fetchJobs()"
                @input="searchJob"
              />
              <v-menu
                v-model="isArchiveOpen"
                :close-on-content-click="false"
                offset-y
                nudge-bottom="8px"
                nudge-left="20px"
                transition="slide-y-reverse-transition"
              >
                <template v-slot:activator="menu">
                  <v-tooltip bottom>
                    <template v-slot:activator="tooltip">
                      <v-icon
                        size="26"
                        class="me-3"
                        v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                        v-on="{ ...menu.on, ...tooltip.on }"
                        @click.stop
                      >
                        {{ icons.mdiArchive }}
                      </v-icon>
                    </template>
                    <span>Job Dihapus / Diarsipkan</span>
                  </v-tooltip>
                </template>
                <ArchivedJob
                  :is-archive-open="isArchiveOpen"
                  :jobs="jobList"
                  @update="fetchJobs()"
                />
              </v-menu>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="26"
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchJobs()"
                  >
                    {{ icons.mdiRefresh }}
                  </v-icon>
                </template>
                <span>Muat Ulang</span>
              </v-tooltip>

              <!-- Create Job -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ms-auto ms-md-4"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$refs.jobForm.show(jobTypeDetail.id)"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>Buat Job</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row
            class="mx-2 mb-2"
          >
            <v-col
              cols="12"
              lg="3"
              md="4"
            >
              <v-select
                v-model="state.jobFilter.value.sort"
                placeholder="Sortir Berdasarkan"
                :items="sortOptions"
                item-text="label"
                return-object
                outlined
                dense
                hide-details
                @change="fetchJobs()"
              />
            </v-col>
            <v-col
              cols="4"
              lg="2"
              md="3"
            >
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    outlined
                    block
                    large
                    v-on="on"
                  >
                    Grup Berdasarkan
                  </v-btn>
                </template>

                <v-list
                  bottom
                  dense
                >
                  <v-subheader>GRUP BERDASARKAN</v-subheader>
                  <v-list-item-group
                    v-model="state.jobFilter.value.group"
                    mandatory
                    color="primary"
                    @change="$refs.jobKanbanView.fetchGroupColumns()"
                  >
                    <v-list-item
                      v-for="option in groupOptions"
                      :key="option.field"
                      :value="option"
                    >
                      {{ option.label }}
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <v-divider />

                <v-list
                  bottom
                  nav
                  dense
                >
                  <v-subheader>URUTKAN</v-subheader>
                  <v-list-item-group
                    v-model="state.jobFilter.value.groupOrder"
                    mandatory
                    color="primary"
                    @change="$refs.jobKanbanView.fetchGroupColumns()"
                  >
                    <v-list-item value="ASC">
                      <v-list-item-icon>
                        <v-icon
                          class="mr-3"
                          v-text="icons.mdiSortAscending"
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="'ASC'"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item value="DESC">
                      <v-list-item-icon>
                        <v-icon
                          class="mr-3"
                          v-text="icons.mdiSortDescending"
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="'DESC'"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
            <v-col
              cols="8"
              lg="2"
              md="3"
            >
              <v-badge
                :value="filterCount > 0"
                bordered
                overlap
                class="w-100 cursor-pointer"
                @click.native="$refs.jobFilterBuilder.clearFilter()"
              >
                <template
                  #badge
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </template>
                <v-btn
                  outlined
                  block
                  large
                  :text="!filterCount"
                  :color="filterCount > 0 ? 'primary' : null"
                  @click.stop="$refs.jobFilterBuilder.show(customAttributesArray)"
                >
                  Advanced Filter {{ filterCount > 0 ? `(${filterCount})` : null }}
                </v-btn>
              </v-badge>
            </v-col>

            <!-- View Switcher -->
            <v-col
              cols="12"
              md="auto"
              class="ms-0 ms-md-auto d-flex justify-space-between"
            >
              <v-btn-toggle
                v-model="viewTab"
                mandatory
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiViewDashboardVariant }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Kanban</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiTable }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Tabel</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiCardText }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Kartu</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Tampilan Gantt Chart</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-card>
        <div
          :key="2"
          style="height: 4px"
        >
          <v-progress-linear
            v-show="loading"
            indeterminate
            color="primary"
          />
        </div>
      </xyztransitiongroup>
    </template>
    <template v-else>
      <v-skeleton-loader
        type="article, actions, table-thead"
        class="mb-6"
      />
    </template>

    <template v-if="!loadingAll">
      <v-tabs-items
        v-model="viewTab"
        class="rounded-lg"
      >
        <!-- Kanban View -->
        <v-tab-item>
          <job-kanban-view
            v-if="viewTab === 0 && jobTypeList[activeTab]"
            :key="3"
            ref="jobKanbanView"
            :types="jobTypeList[activeTab]"
            :jobs.sync="jobList"
            :options.sync="state.jobFilter.value.pagination"
            :items-per-page="state.jobFilter.value.pagination.itemsPerPage"
            :server-items-length.sync="jobCount"
            @showtask="$refs.taskForm.show($event.customer, $event.job)"
            @delete="deleteJob($event)"
            @update="$refs.jobForm.update($event)"
            @pagination="state.jobFilter.value.pagination = $event; fetchJobs()"
            @refetch="fetchJobs()"
          />
        </v-tab-item>

        <!-- Syncfusion Grid -->
        <v-tab-item class="grid-container">
          <ejs-grid
            v-if="jobList"
            id="jobGrid"
            ref="jobGrid"
            :columns="gridColumns"
            :toolbar="toolbarGrid"
            :data-source="jobList"
            :edit-settings="gridEditSettings"
            :page-settings="pageSettings"
            :allow-paging="true"
            :allow-sorting="true"
            :allow-multi-sorting="true"
            :allow-multi-grouping="true"
            :allow-grouping="true"
            :allow-resizing="true"
            :allow-reordering="true"
            :allow-pdf-export="true"
            :allow-excel-export="true"
            :show-column-chooser="true"
            :action-begin="handleActionBegin"
            :data-bound="dataBound"
            :group-settings="groupSettings"
            :context-menu-items="gridContextMenuItems"
            height="50vh"
          />
        </v-tab-item>

        <!-- Card View -->
        <v-tab-item class="mx-4 mt-5">
          <v-data-iterator
            :items="jobList"
            :items-per-page="20"
            item-key="id"
            disable-sort
            :footer-props="{
              'items-per-page-options': [20, 40, 60],
              'items-per-page-text': 'Jumlah data',
            }"
            :loading="loading"
          >
            <template v-slot:default="props">
              <v-row class="match-height">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                >
                  <job-card
                    :data="item"
                    @showtask="$refs.taskForm.show(item.customer, item)"
                    @delete="deleteJob($event)"
                    @update="$refs.jobForm.update($event)"
                    @refetch="fetchJobs()"
                  />
                </v-col>
              </v-row>
            </template>
            <template
              #footer.page-text="page"
            >
              Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
            </template>
          </v-data-iterator>
        </v-tab-item>

        <!-- Syncfusion Gantt -->
        <v-tab-item
          class="h-full scroll-gantt gantt-container"
        >
          <ejs-gantt
            id="jobGantt"
            ref="jobGantt"
            :data-source="jobList"
            :task-fields="ganttFields"
            :toolbar="toolbarGantt"
            :label-settings="labelSettings"
            :edit-settings="ganttEditSettings"
            :allow-reordering="true"
            :allow-resizing="true"
            :include-weekend="true"
            :enable-context-menu="true"
            :context-menu-items="ganttContextMenuItems"
            :enable-virtualization="true"
            :enable-virtual-mask-row="false"
            height="50vh"
          />
        </v-tab-item>
      </v-tabs-items>

      <job-type-form
        ref="typeForm"
        @success="getJobTypeData()"
      />

      <job-form
        ref="jobForm"
        @success="fetchJobs()"
      />

      <task-form ref="taskForm" />

      <edit-job-type-permission-form
        ref="editFolderPermissionForm"
      />

      <job-filter-builder
        ref="jobFilterBuilder"
        :status="jobTypeDetail.statuses"
        :priority="jobTypeDetail.priorities"
        @close="fetchJobs()"
      />
    </template>
    <template v-else>
      <v-skeleton-loader
        type="table-thead, table-tbody, table-tfoot"
      />
    </template>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import {
  mdiMagnify, mdiPlus, mdiClose, mdiTable, mdiCardText, mdiRefresh, mdiArchive,
  mdiViewDashboardVariant, mdiPencil, mdiDotsVertical, mdiAccountPlus, mdiMenuDown,
  mdiLockOutline, mdiCalendarMonth, mdiChevronDown, mdiSortAscending, mdiSortDescending,
} from '@mdi/js'
import { useStorage, useDebounceFn } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import { ellipsis, avatarText } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import JobTypeForm from '@/views/job/components/JobTypeForm.vue'
import JobCard from '@/views/job/components/JobCard.vue'
import errorHandling from '@/utils/errorHandling'
import dateFormat from '@/utils/dateFormat'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import JobKanbanView from '@/views/job/components/JobKanbanView.vue'
import JobForm from '@/views/job/components/JobForm.vue'
import useJob from '@/composables/useJob'
import store from '@/store'
import ArchivedJob from '@/views/job/components/ArchivedJob.vue'
import jobUpdateSubscription from '@/graphql/subscription/jobUpdateSubscription'
import EditJobTypePermissionForm from '@/views/folder/EditJobTypePermissionForm.vue'
import useFolder from '@/composables/useFolder'
import { jobs } from '@/graphql/queries'
import router from '@/router'
import Vue from 'vue'
import JobTypeFilter from '@/views/folder/JobTypeFilter.vue'
import {
  Page,
  Sort,
  Group,
  Freeze,
  Resize as GridResize,
  Reorder as GridReorder,
  PdfExport,
  ExcelExport,
  Toolbar as GridToolbar,
  Edit as GridEdit,
  ColumnChooser,
  LazyLoadGroup,
  ContextMenu as GridContextMenu,
} from '@syncfusion/ej2-vue-grids'
// eslint-disable-next-line import/no-extraneous-dependencies
import { enableRipple } from '@syncfusion/ej2-base'
import {
  Edit,
  Selection,
  Reorder,
  Resize,
  ContextMenu,
  VirtualScroll,
  Toolbar,
} from '@syncfusion/ej2-vue-gantt'
import { getVuetify } from '@/@core/utils'
import useCustomAttributes from '@/composables/useCustomAttributes'
import SelectMultipleColumn from '@/views/custom-attributes/SelectMultipleColumn.vue'
import StatusColumn from './columns/StatusColumn.vue'
import PriorityColumn from './columns/PriorityColumn.vue'
import AssigneeColumn from './columns/AssigneeColumn.vue'
import CreatedByColumn from './columns/CreatedByColumn.vue'
import CustomerColumn from './columns/CustomerColumn.vue'
import NameColumn from './columns/NameColumn.vue'
import JobFilterBuilder from '@/views/job/components/JobFilterBuilder.vue'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    TaskForm,
    InputIconToggle,
    JobTypeForm,
    JobCard,
    JobKanbanView,
    JobForm,
    ArchivedJob,
    EditJobTypePermissionForm,
    JobTypeFilter,
    JobFilterBuilder,
  },
  provide: {
    grid: [
      Page,
      Sort,
      Group,
      Freeze,
      GridResize,
      GridReorder,
      PdfExport,
      GridToolbar,
      ExcelExport,
      GridEdit,
      ColumnChooser,
      LazyLoadGroup,
      GridContextMenu,
    ],
    gantt: [
      Edit,
      Selection,
      Reorder,
      Resize,
      Toolbar,
      ContextMenu,
      VirtualScroll,
    ],
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const {
      fetchFolderDetail, loadingDetailFolder, folderDetail,
      folderPermissionGuard,
    } = useFolder()

    const {
      getJobTypes, loadingJobType, jobTypeList,
      fetchJobTypeDetail, loadingJobTypeDetail, jobTypeDetail,
      loadingJob, jobList, jobCount,
      removeJobType, loadingDeleteJobType,
      updateArchiveJobType, loadingArchiveJobType,
      updateUnarchiveJobType,
    } = useJob()

    const {
      customAttributesArray,
      fetchCustomAttributes,
    } = useCustomAttributes({ objectTypeId: 2 })

    const jobForm = ref(null)

    /* Syncfusion Grid Properties */

    enableRipple(true) // enable ripple effect on syncfusion components
    const jobGrid = ref(null)

    const selectMultipleColumn = () => ({
      template: {
        extends: SelectMultipleColumn,
        propsData: { customAttributes: customAttributesArray.value },
      },
    })
    const statusColumn = () => ({ template: StatusColumn })
    const priorityColumn = () => ({ template: PriorityColumn })
    const assigneeColumn = () => ({ template: AssigneeColumn })
    const createdByColumn = () => ({ template: CreatedByColumn })
    const customerColumn = () => ({ template: CustomerColumn })
    const nameColumn = () => ({ template: NameColumn })

    const singleAccess = (field, data) => {
      const customAttributeId = Number(field.split('.', 2)[1])

      if (
        data.custom_attribute_values === null
        || data.custom_attribute_values[customAttributeId] === undefined
      ) {
        return ''
      }

      const customAttribute = customAttributesArray.value.find(el => el.custom_attribute.id === customAttributeId).custom_attribute

      return (customAttribute.options.find(el => el.id === Number(data.custom_attribute_values[customAttributeId])).name)
    }
    const multipleAccess = (field, data) => {
      const customAttributeId = Number(field.split('.', 2)[1])

      if (
        data.custom_attribute_values === null
        || data.custom_attribute_values[customAttributeId] === undefined
      ) {
        return ''
      }

      const customAttribute = customAttributesArray.value.find(el => el.custom_attribute.id === customAttributeId).custom_attribute

      return JSON.parse(data.custom_attribute_values[customAttributeId]).map(el => customAttribute.options.find(opt => opt.id === el).name)
    }
    const assigneeAccess = (field, data) => data[field].map(el => el.user.name).join(', ')

    const gridColumns = ref([
      {
        field: 'name',
        dataTypeId: null,
        headerText: 'Nama',
        width: '200',
        textAlign: 'Left',
        showInColumnChooser: false,
        allowReordering: false,
        allowGrouping: false,
        template: nameColumn,
      },
      {
        field: 'priority.name',
        dataTypeId: null,
        headerText: 'Prioritas',
        width: '200',
        textAlign: 'Left',
        template: priorityColumn,
      },
      {
        field: 'status.name',
        dataTypeId: null,
        headerText: 'Status',
        width: '200',
        textAlign: 'Left',
        template: statusColumn,
      },
      {
        field: 'customer.name',
        dataTypeId: null,
        headerText: 'Pelanggan',
        width: '200',
        textAlign: 'Left',
        template: customerColumn,
      },
      {
        field: 'expected_close_date',
        dataTypeId: null,
        headerText: 'Ekspektasi Waktu Ditutup',
        width: '220',
        textAlign: 'Left',
        type: 'dateTime',
        format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
      },
      {
        field: 'created_by.name',
        dataTypeId: null,
        headerText: 'Dibuat Oleh',
        width: '200',
        textAlign: 'Left',
        template: createdByColumn,
      },
      {
        field: 'assignedTo',
        dataTypeId: null,
        headerText: 'Ditugaskan ke',
        width: '220',
        textAlign: 'Left',
        allowSorting: false,
        allowEditing: false,
        allowResizing: false,
        valueAccessor: assigneeAccess,
        template: assigneeColumn,
      },
    ])
    const pageSettings = ref(
      {
        pageSizes: [20, 40, 60],
        pageSize: 20,
        currentPage: 1,
      },
    )
    const gridEditSettings = ref({
      allowEditing: true,
      allowDeleting: true,
      mode: 'Dialog',
    })
    const groupSettings = ref({
      enableLazyLoading: true,
    })
    const gridContextMenuItems = ref([
      'AutoFit', 'AutoFitAll',
      'PdfExport', 'ExcelExport',
      'SortAscending', 'SortDescending',
      'Copy', 'Edit', 'Delete', 'Save', 'Cancel',
      'FirstPage', 'PrevPage', 'LastPage', 'NextPage',
    ])
    const toolbarGrid = ref([
      'PdfExport',
      'ExcelExport',
      'Edit',
      'ColumnChooser',
      {
        text: 'Saved Views',
        tooltipText: 'Tampilan Tabel',
        prefixIcon: 'e-expand',
        id: 'saved_views',
        align: 'right',
      },
    ])

    const flag = ref(true)
    const dataBound = () => {
      if (flag.value === true) {
        flag.value = false

        // remove all custom attribute columns
        gridColumns.value = gridColumns.value.filter(el => el.dataTypeId === null)

        // push custom attributes gridColumns properties
        customAttributesArray.value.forEach(el => {
          if ([1, 2].includes(el.custom_attribute.data_type.id)) {
            gridColumns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 3) {
            gridColumns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              type: 'dateTime',
              format: { type: 'dateTime', format: 'dd MMMM yyyy · hh:mm' },
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 4) {
            gridColumns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              valueAccessor: singleAccess,
              dataTypeId: el.custom_attribute.data_type.id,
            })
          } else if (el.custom_attribute.data_type.id === 5) {
            gridColumns.value.push({
              field: `custom_attribute_values.${el.custom_attribute.id}`,
              headerText: el.custom_attribute.name,
              width: '200',
              textAlign: 'Left',
              allowSorting: false,
              valueAccessor: multipleAccess,
              template: selectMultipleColumn,
              dataTypeId: el.custom_attribute.data_type.id,
            })
          }
        })
      }
    }

    /* eslint-disable no-param-reassign */
    const handleActionBegin = args => {
      switch (args.requestType) {
        case 'beginEdit':
          args.cancel = true
          jobForm.value.update(args.rowData.id)
          break
        default:
          break
      }
    }
    /* eslint-enable no-param-reassign */

    /* End of Syncfusion Grid Properties */

    /* Syncfusion Gantt Properties */

    const jobGantt = ref(null)
    const ganttFields = ref({
      id: 'id',
      name: 'name',
      startDate: 'start_date',
      endDate: 'expected_close_date',
    })
    const ganttEditSettings = ref({
      allowAdding: true,
      allowEditing: true,
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      allowTaskbarEditing: true,
    })
    const labelSettings = ref({
      leftLabel: 'name',
    })
    const ganttContextMenuItems = ref([
      'AutoFitAll',
      'AutoFit',
      'TaskInformation',
      'DeleteTask',
      'Save',
      'Cancel',
      'SortAscending',
      'SortDescending',
      'Add',
      'Save',
      'Cancel',
    ])
    const toolbarGantt = ref(['ZoomIn', 'ZoomOut', 'ZoomToFit'])

    /* End of Syncfusion Gantt Properties */

    const searchFields = [
      {
        field: 'name',
        label: 'Nama',
        value: true,
      },
      {
        field: 'strip_description',
        label: 'Deskripsi',
        value: true,
      },
      {
        field: 'created_by',
        label: 'Dibuat Oleh',
        value: true,
      },
      {
        field: 'customer',
        label: 'Pelanggan',
        value: false,
      },
      {
        field: 'assigned_to',
        label: 'Ditugaskan ke',
        value: false,
      },
      {
        field: 'custom_attribute_values',
        label: 'Custom Field',
        value: false,
      },
    ]

    const isArchiveOpen = ref(false)
    const activeTab = ref(0)
    const viewTab = useStorage('viewTabJobs', 0)
    const loading = ref(false)
    const selectedRows = ref([])
    const headers = ref([
      {
        text: 'Nama',
        value: 'name',
        width: '320px',
      },
      {
        text: 'Prioritas - Job Type - Status',
        value: 'status',
        class: 'w-vdt-280',
      },
      {
        text: 'Pelanggan',
        value: 'customer',
        class: 'w-vdt-200',
      },
      {
        text: 'Ekspektasi Waktu Ditutup',
        value: 'expected_close_date',
        class: 'w-vdt-200',
      },
      {
        text: 'Dibuat Oleh',
        value: 'created_by',
        class: 'w-vdt-200',
      },
      {
        text: 'Ditugaskan ke',
        value: 'assignedTo',
        width: '220px',
      },
      {
        text: '',
        value: 'action',
      },
    ])

    const jobKanbanView = ref(null)
    const { deleteJob: delJob } = useJob()
    const state = {
      ...useFieldJob(['jobFilter', 'activeTabKanban', 'jobTypeFilter']),
    }
    const isJobTypeFilterOpen = ref(false)

    const jobTypeFilterCount = computed(() => {
      let count = 0
      if (state.jobTypeFilter.value.my_jobType.value !== null) count += 1
      if (state.jobTypeFilter.value.show_archived.value) count += 1
      if (state.jobTypeFilter.value.sort.id !== 1) count += 1

      return count
    })

    const loadingInitial = computed(() => {
      if (loadingDetailFolder.value) return true
      if (loadingJobType.value) return true

      return false
    })

    const loadingAll = computed(() => {
      if (loadingInitial.value) return true
      if (loadingJobTypeDetail.value) return true
      if (loadingJob.value) return true
      if (loading.value) return true

      return false
    })

    const fetchJobs = () => {
      const filter = state.jobFilter.value
      // console.log(state.jobFilter.value)
      loading.value = true
      apolloClient.query({
        query: jobs,
        fetchPolicy: 'no-cache',
        variables: {
          filter: {
            search: filter.search.length ? filter.search : null,
            sort: filter.sort.field,
            order: filter.sort.type,
            job_type: filter.types.length ? filter.types : null,
            status: filter.status.length ? filter.status.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            created_by: filter.created_by.length ? filter.created_by.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            priority: filter.priority.length ? filter.priority.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            customer: filter.customer.length ? filter.customer.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            assigned_to: filter.assigned_to.length ? filter.assigned_to.map(data => ({
              operator: data.operator,
              match_type: data.match_type,
              value: data.value,
              condition: data.condition,
            })) : null,
            expected_close_date: filter.expected_close_date.length ? filter.expected_close_date.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            updated_at: filter.updated_at.length ? filter.updated_at.map(data => ({
              operator: data.operator,
              value: data.value,
              condition: data.condition,
            })) : null,
            search_field: filter.search_field.filter(el => el.value).map(el => el.field),
            custom_filter: filter.custom_filter.length ? filter.custom_filter.map(data => ({
              attribute_id: data.fieldInput.id,
              data_type_id: data.fieldInput.data_type.id,
              operator: data.operator,
              match_type: data.match_type,
              condition: data.condition,
              value: data.value?.toString(),
            })) : null,
          },
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(async result => {
        await fetchCustomAttributes(2, filter.types[0])
        jobList.value = result.data.jobs.jobs
        jobCount.value = result.data.jobs.count
        setTimeout(() => {
          loading.value = false

          // delay before render again
          if (jobKanbanView.value) jobKanbanView.value.renderList()
        }, 100)
      }).catch(err => {
        loading.value = false
        router.push('/error')
        errorHandling(err)
      })
    }

    const getJobTypeData = () => {
      isJobTypeFilterOpen.value = false
      getJobTypes(+props.id, {
        search: state.jobTypeFilter.value.search,
        sort: state.jobTypeFilter.value.sort.value,
        sortType: state.jobTypeFilter.value.sort.sortType,
        show_archived: state.jobTypeFilter.value.show_archived.value,
        my_jobType: state.jobTypeFilter.value.my_jobType.value,
      }).then(result => {
        const activeTabId = activeTab.value >= result.data.jobTypes.length
          ? result.data.jobTypes.length - 1
          : state.activeTabKanban.value
        state.jobFilter.value.types = [result.data.jobTypes[activeTabId].id]
        activeTab.value = state.activeTabKanban.value ?? 0
        fetchJobTypeDetail(jobTypeList.value[activeTabId].id).then(() => {
          console.log('fetchJobTypeDetail')
          fetchJobs()
          jobKanbanView.value.fetchJobStatus(result.data.jobTypes[activeTabId].id)
          jobKanbanView.value.renderList()
        })
      })
    }

    const $vuetify = getVuetify()
    const currentTheme = ref($vuetify.theme.dark)

    const setTheme = () => {
      if ($vuetify.theme.dark) {
        document.getElementById('theme').href = '/syncfusion-theme/grid/ej2-grid-dark.css'
        document.getElementById('theme').href = '/syncfusion-theme/gantt/ej2-gantt-dark.css'
        currentTheme.value = true
      } else {
        document.getElementById('theme').href = '/syncfusion-theme/grid/ej2-grid-light.css'
        document.getElementById('theme').href = '/syncfusion-theme/gantt/ej2-gantt-light.css'
        currentTheme.value = false
      }
    }

    watch($vuetify, () => {
      if (currentTheme.value !== $vuetify.theme.dark) {
        setTheme()
      }
    })

    onMounted(() => {
      setTheme()
      fetchFolderDetail(+props.id)
      getJobTypeData()
    })

    const sortOptions = [
      {
        label: 'Nama Job A-Z',
        field: 'name',
        type: 'ASC',
      },
      {
        label: 'Nama Job Z-A',
        field: 'name',
        type: 'DESC',
      },
      {
        label: 'Tanggal Ditutup Terlama',
        field: 'expected_close_date',
        type: 'ASC',
      },
      {
        label: 'Tanggal Ditutup Terdekat',
        field: 'expected_close_date',
        type: 'DESC',
      },
      {
        label: 'Diupdate Terlama',
        field: 'updated_at',
        type: 'ASC',
      },
      {
        label: 'Diupdate Terbaru',
        field: 'updated_at',
        type: 'DESC',
      },
    ]

    const groupOptions = [
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'Prioritas',
        field: 'priority',
      },
    ]

    watch(activeTab, value => {
      state.activeTabKanban.value = value
    })

    const clearFilter = () => {
      store.dispatch('job/resetFilter')
      fetchJobs()
    }

    const searchJob = useDebounceFn(() => {
      fetchJobs()
    }, 1000)

    const deleteJob = id => {
      delJob(id).then(() => {
        fetchJobs()
      })
    }

    const filterCount = computed(() => {
      const filter = state.jobFilter.value
      let count = 0

      if (filter.search) count += 1
      if (filter.created_by.length) count += 1
      if (filter.assigned_to.length) count += 1
      if (filter.status.length) count += 1
      if (filter.customer.length) count += 1
      if (filter.priority.length) count += 1
      if (filter.updated_at.length) count += 1
      if (filter.expected_close_date.length) count += 1

      return count
    })

    const observer = apolloClient.subscribe({
      query: jobUpdateSubscription,
      fetchPolicy: 'no-cache',
    })

    observer.subscribe({
      next(sub) {
        const update = sub.data.jobUpdateSubscription
        console.log('receiveJob', update.priority)

        fetchJobs()

        /* const idx = jobList.value.findIndex(el => el.id === update.id)
        jobList.value.splice(idx, 1, update)
        console.log(jobList.value[idx]) */
      },
      error(error) {
        console.error(error)
        errorHandling(error)
      },
    })

    const confirmArchiveJobType = () => {
      Vue.$dialog({
        title: 'Arsip Pipeline?',
        body: 'Yakin ingin mengarsipkan job type ini?',
      }).then(confirm => {
        if (confirm) {
          updateArchiveJobType(jobTypeDetail.value.id).then(() => {
            getJobTypeData()
          })
        }
      })
    }

    const confirmUnarchiveJobType = () => {
      Vue.$dialog({
        title: 'Arsip Pipeline?',
        body: 'Yakin ingin memulihkan job type ini?',
      }).then(confirm => {
        if (confirm) {
          updateUnarchiveJobType(jobTypeDetail.value.id).then(() => {
            getJobTypeData()
          })
        }
      })
    }

    const confirmDeleteJobType = () => {
      Vue.$dialog({
        title: 'Hapus Pipeline?',
        body: 'Yakin ingin menghapus job type ini?',
      }).then(confirm => {
        if (confirm) {
          removeJobType(jobTypeDetail.value.id).then(() => {
            getJobTypeData()
          })
        }
      })
    }

    const jobFilterBuilder = ref(null)

    const switchJobType = id => {
      flag.value = true
      fetchJobTypeDetail(id)
      fetchJobs()
    }

    const groupBy = ref(0)

    return {
      getJobTypeData,
      jobTypeDetail,
      // fetchJobStatus,
      // fetchJobPriority,
      isArchiveOpen,
      activeTab,
      viewTab,
      jobList,
      jobCount,
      loading,
      selectedRows,
      headers,
      clearFilter,
      // jobStatusList,
      jobKanbanView,
      ellipsis,
      deleteJob,
      sortOptions,
      state,
      dateFormat,
      searchJob,
      filterCount,
      avatarText,

      icons: {
        mdiMagnify,
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiViewDashboardVariant,
        mdiPencil,
        mdiDotsVertical,
        mdiRefresh,
        mdiArchive,
        mdiAccountPlus,
        mdiMenuDown,
        mdiLockOutline,
        mdiCalendarMonth,
        mdiChevronDown,
        mdiSortAscending,
        mdiSortDescending,
      },

      jobGrid,
      toolbarGrid,
      pageSettings,
      gridEditSettings,
      gridContextMenuItems,
      groupSettings,
      gridColumns,
      dataBound,

      ganttFields,
      jobGantt,
      labelSettings,
      ganttContextMenuItems,
      ganttEditSettings,
      toolbarGantt,
      jobFilterBuilder,

      searchFields,

      loadingDetailFolder,
      folderDetail,

      loadingInitial,
      loadingAll,
      jobTypeList,
      jobForm,
      handleActionBegin,

      loadingJobTypeDetail,
      fetchJobs,
      folderPermissionGuard,
      confirmDeleteJobType,
      loadingDeleteJobType,

      confirmArchiveJobType,
      loadingArchiveJobType,

      confirmUnarchiveJobType,
      switchJobType,

      groupBy,
      groupOptions,

      jobTypeFilterCount,
      isJobTypeFilterOpen,
      customAttributesArray,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.e-content {
  @include style-scroll-bar();
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    background: none;
    padding-top: 16px;
  }
}

.grid-container:after,
.grid-container::after {
  clear: both;
  content: "";
  display: table;
}

.e-grid {
  border-width: 0 !important;
}
.e-groupdroparea {
  border-radius: 9px 9px 0 0;
}
.e-gantt > .e-gantt-toolbar {
  border-width: 1px 0 !important;
  border-radius: 9px 9px 0 0;
}
</style>
