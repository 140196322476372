<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    max-width="1080"
    scrollable
    persistent
  >
    <v-form
      ref="jobTypeForm"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <span class="text-h6 font-medium primary--text">Edit Permission Job Type {{ jobTypeData.name }}</span>
          <v-icon
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <template v-if="!loadingHierarchyData">
          <v-card-text class="pt-2">
            <v-row class="mt-0">
              <v-col
                cols="12"
                md="4"
              >
                <div class="mb-1">
                  <span class="text-caption">Hak akses</span>
                </div>
                <v-btn
                  min-height="110"
                  min-width="110"
                  outlined
                  class="me-4"
                  :text="!formData.isPublic"
                  :color="!formData.isPublic ? null : 'primary'"
                  @click="makePublic"
                >
                  <div class="d-flex flex-column align-center">
                    <v-icon
                      class="text--disabled mb-2"
                      size="40"
                    >
                      {{ icons.mdiAccountGroup }}
                    </v-icon>
                    <span :class="!formData.isPublic ? 'text--disabled' : ''">Publik</span>
                  </div>
                </v-btn>
                <v-btn
                  min-height="110"
                  min-width="110"
                  outlined
                  :text="formData.isPublic"
                  :color="formData.isPublic ? null : 'primary'"
                  @click="makePrivate"
                >
                  <div class="d-flex flex-column align-center">
                    <v-icon
                      class="text--disabled mb-2"
                      size="40"
                    >
                      {{ icons.mdiShieldLock }}
                    </v-icon>
                    <span :class="formData.isPublic ? 'text--disabled' : ''">Kustom</span>
                  </div>
                </v-btn>
              </v-col>

              <v-col
                v-if="!formData.isPublic && selection"
                cols="12"
                md="8"
              >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <div class="mb-1">
                      <span class="text-caption">Visibilitas</span>
                    </div>
                    <v-menu
                      offset-y
                      nudge-bottom="8px"
                      content-class="vispicker"
                    >
                      <template v-slot:activator="menu">
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="tooltip">
                            <v-sheet
                              v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                              width="60"
                              height="40"
                              class="d-flex align-center justify-center"
                              outlined
                              rounded
                              v-on="{ ...menu.on, ...tooltip.on }"
                            >
                              <v-icon size="20">
                                {{ selection.icon }}
                              </v-icon>
                            </v-sheet>
                          </template>
                          <span>{{ selection.name }}</span>
                        </v-tooltip>
                      </template>
                      <v-card max-width="300px">
                        <v-list
                          nav
                        >
                          <v-list-item-group
                            v-model="selection"
                            @change="changeVisibility"
                          >
                            <v-list-item
                              v-for="item in visibilityOptions"
                              :key="item.id"
                              :value="item"
                              class="d-flex align-start py-1 mb-1"
                            >
                              <v-icon
                                size="20"
                                class="mt-1"
                              >
                                {{ item.icon }}
                              </v-icon>
                              <div class="ml-2 d-flex flex-column mr-2">
                                <span class="text-subtitle-2 primary--text">{{ item.name }}</span>
                                <span class="mt-1 text-caption">{{ item.description }}</span>
                              </div>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col
                    v-if="selection"
                    cols="12"
                  >
                    <user-auto-suggest
                      :value="selectedUsers"
                      label="User yang memiliki akses"
                      multiple
                      :filter="filterUser"
                      @input="changeUser"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              v-if="selection && !formData.isPublic"
            >
              <v-col cols="12">
                <v-treeview
                  v-model="selectedUsers"
                  open-all
                  item-key="node_id"
                  :items="hierarchyTreeFolderData"
                  class="mt-4"
                  selectable
                  selection-type="leaf"
                  return-object
                  item-disabled="disabled"
                >
                  <template #label="{ item }">
                    <div
                      v-if="!item.children"
                    >
                      <v-avatar
                        size="36"
                        class="mr-2 v-avatar-light-bg"
                      >
                        <v-img
                          v-if="item.photo"
                          :src="item.photo"
                        />
                        <span v-else>{{ avatarText(item.name) }}</span>
                      </v-avatar>
                      <span>{{ item.name }}</span>
                      <v-chip
                        v-if="item.disabled"
                        color="primary"
                        label
                        outlined
                        class="ms-4"
                      >
                        Saya Pemilik
                      </v-chip>
                    </div>
                    <span v-else>{{ item.name }}</span>
                  </template>
                  <template #append="{ item, selected }">
                    <div
                      v-if="!item.children"
                      style="max-width: 150px;"
                    >
                      <v-select
                        v-model="item.permission"
                        :items="permissionList"
                        :disabled="!selected"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details="auto"
                        class="my-1"
                      />
                    </div>
                  </template>
                </v-treeview>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <div
          v-else
          class="d-flex flex-column align-center justify-center pa-5"
          height="200px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          />
        </div>
        <v-card-actions>
          <div
            class="mt-4 ml-md-auto"
          >
            <v-btn
              color="primary"
              :loading="loadingEditJobType"
              :disabled="loadingHierarchyData"
              @click="submitForm"
            >
              Simpan
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiAccountGroup, mdiShieldLock } from '@mdi/js'
import useVisibility from '@/composables/useVisibility'
import useTeam from '@/composables/useTeam'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import useHierarchy from '@/composables/useHierarchy'
import { avatarText } from '@core/utils/filter'
// import store from '@/store'
import useJob from '@/composables/useJob'

export default {
  components: {
    UserAutoSuggest,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // const userLogin = computed(() => store.getters.getUserData)
    const isOpen = ref(false)
    const jobTypeForm = ref(null)
    const formData = ref({
      isPublic: true,
      userPermissions: [],
    })
    // store.dispatch('getProfileInfo')
    // store.dispatch('permissionsParser')
    const { visibilityOptions, fetchVisibilities } = useVisibility()
    const { fetchJobTypeDetail, loadingJobTypeDetail } = useJob()
    const {
      loadingSearchUsersTeams, searchUsersTeamsResults, searchUsersTeams,
    } = useTeam()
    const selection = ref(null)
    const loadingHierarchyData = ref(false)
    const selectedUsers = ref([])
    const permissionList = ref([
      {
        id: 1,
        name: 'Admin',
      },
      {
        id: 2,
        name: 'Editor',
      },
      {
        id: 3,
        name: 'Comment Only',
      },
      {
        id: 4,
        name: 'View Only',
      },
    ])
    const {
      // eslint-disable-next-line no-unused-vars
      fetchHierarchyTreeFolder, isLoadingHierarchyTreeFolder, hierarchyTreeFolderData,
    } = useHierarchy()

    const { updateJobTypePermission, loadingEditJobType } = useJob()

    const makePublic = () => { formData.value.isPublic = true }

    const jobTypeData = ref(null)

    const makePrivate = () => {
      formData.value.isPublic = false
      // eslint-disable-next-line prefer-destructuring
      selection.value = visibilityOptions.value[4]
    }

    const resolvePermissionType = permissionType => {
      switch (permissionType) {
        case 'Admin':
          return permissionList.value[0].id
        case 'Editor':
          return permissionList.value[1].id
        case 'CommentOnly':
          return permissionList.value[2].id
        case 'ViewOnly':
          return permissionList.value[3].id
        default:
          return permissionList.value[0].id
      }
    }

    const setUserPermissionType = (data, permission) => {
      data.forEach(el => {
        if (!el.children && el.id === permission.user.id) {
          // eslint-disable-next-line no-param-reassign
          el.permission = resolvePermissionType(permission.permission_type)
        }
        if (el.children) {
          setUserPermissionType(el.children, permission)
        }
      })
    }

    const loadData = jobTypeId => {
      fetchVisibilities().then(() => {
        visibilityOptions.value.pop()
        // eslint-disable-next-line prefer-destructuring
        selection.value = visibilityOptions.value[visibilityOptions.value.length - 1]
      })
      loadingHierarchyData.value = true
      fetchHierarchyTreeFolder(() => {
        fetchJobTypeDetail(jobTypeId).then(res => {
          formData.value.isPublic = res.is_public
          formData.value.id = res.id
          if (res.is_public) {
            makePublic()
          } else {
            makePrivate()
          }

          selectedUsers.value = res.folder_permissions.map(el => {
            setUserPermissionType(hierarchyTreeFolderData.value, el)

            return {
              id: el.user.id,
              node_id: el.user.id.toString(),
              name: el.user.name,
              photo: el.user.photo,
              permission: resolvePermissionType(el.permission_type),
            }
          })
          loadingHierarchyData.value = false
        })
      })
    }

    const reset = () => {
      formData.value = {
        isPublic: true,
        userPermissions: [],
      }
      selection.value = null
      selectedUsers.value = []
      jobTypeForm.value.reset()
    }

    const show = jobType => {
      jobTypeData.value = jobType
      isOpen.value = true
      loadData(jobType.id)
    }

    const close = () => {
      reset()
      isOpen.value = false
    }

    const submitForm = () => {
      console.log(formData.value.userPermissions)
      if (jobTypeForm.value.validate()) {
        if (!formData.value.isPublic) {
          selectedUsers.value.forEach(el => {
            if (!el.children) {
              formData.value.userPermissions.push({
                user_id: el.id,
                permission_type: el.permission,
              })
            }
          })
        }

        updateJobTypePermission(formData.value).then(() => {
          close()
          emit('onSubmit')
        })
      }
    }

    const changeVisibility = () => {
      selectedUsers.value = []
      // mengambil user atasan
      if (selection.value.id >= 2 && selection.value.id <= 4) {
        searchUsersTeams('', selection.value.id, () => {
          selectedUsers.value = searchUsersTeamsResults.value.users.map(el => ({
            ...el, node_id: el.id.toString(),
          }))
        })
      }
    }

    const filterUser = (item, queryText) => item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

    const changeUser = val => {
      const newVal = val.map(el => ({
        ...el,
        node_id: el.id.toString(),
      }))

      selectedUsers.value = newVal
    }

    return {
      isOpen,
      show,
      formData,
      icons: {
        mdiClose,
        mdiAccountGroup,
        mdiShieldLock,
      },
      close,

      visibilityOptions,
      fetchVisibilities,
      loadingSearchUsersTeams,
      searchUsersTeamsResults,
      searchUsersTeams,
      selection,
      selectedUsers,

      permissionList,
      submitForm,
      jobTypeForm,
      makePublic,
      makePrivate,
      changeVisibility,
      // userLoginHierarchy,
      avatarText,
      hierarchyTreeFolderData,
      loadingJobTypeDetail,
      jobTypeData,
      loadingHierarchyData,
      filterUser,
      changeUser,
      loadingEditJobType,
    }
  },
}
</script>
