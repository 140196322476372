<template>
  <div>
    <router-link :to="{ name: 'job-detail', params: { id: data.id } }">
      <span class="primary--text font-medium d-block">{{ data.name }}</span>
    </router-link>
    <span class="text-caption text--disabled mb-1">{{ data.strip_description }}</span>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
