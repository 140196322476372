import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[(_vm.data.assignedTo.length)?_c('limit-elements',{staticClass:"v-avatar-group mr-auto",class:_vm.rootThemeClasses,attrs:{"elements":_vm.data.assignedTo,"limit":3},scopedSlots:_vm._u([{key:"default",fn:function(el){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"primary",attrs:{"size":"36"}},'v-avatar',attrs,false),on),[(el.data.user.photo)?_c(VImg,{attrs:{"src":el.data.user.photo}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.avatarText(((el.data.user.first_name) + " " + (el.data.user.last_name))))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(el.data.user.first_name)+" "+_vm._s(el.data.user.last_name))])])]}},{key:"others",fn:function(el){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"primary mb-1",attrs:{"size":"36"}},'v-avatar',attrs,false),on),[(el.data.user.photo)?_c(VImg,{attrs:{"src":el.data.user.photo}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.avatarText(((el.data.user.first_name) + " " + (el.data.user.last_name))))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(el.data.user.first_name)+" "+_vm._s(el.data.user.last_name))])])]}},{key:"others-activator",fn:function(el){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"primary--text text-caption",attrs:{"size":"36","color":"white"}},'v-avatar',attrs,false),on),[_c('span',[_vm._v("+"+_vm._s(el.data.limit))])])]}}],null,true)},[_c('span',[_vm._v("Lihat User Lain")])])]}}],null,false,3879178144)}):_vm._e(),_c(VMenu,{attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","outlined":"","small":"","rounded":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',Object.assign({}, menu.attrs, tooltip.attrs),false),Object.assign({}, menu.on, tooltip.on)),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountPlus)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Tugaskan ke Pengguna")])])]}}])},[_c('assign-object',{attrs:{"job-id":_vm.data.id,"data":_vm.data.assignedTo},on:{"success":function($event){return _vm.$emit('refetch')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }