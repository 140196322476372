<template>
  <div v-if="data.customer">
    <router-link :to="{ name: 'customer-detail', params: { id: data.customer.id } }">
      <span class="text-subtitle-2">{{ data.customer.name }}</span>
    </router-link>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
