<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000px"
    @input="!$event ? $emit('close') : null"
  >
    <v-card
      class="pa-4"
    >
      <div class="d-flex justify-space-between mb-4">
        <span class="text-h6 font-medium primary--text">Advanced Filter Job</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-form
        ref="jobFilterBuilder"
        lazy-validation
        @submit.prevent="filter"
      >
        <v-row
          v-for="(filter, index) in jobFilter"
          :key="index"
        >
          <!-- Condition Input -->
          <v-col
            v-if="index === 0"
            cols="12"
            md="1"
            align-self="center"
            class="text-center"
          >
            Where
          </v-col>
          <v-col
            v-else
            cols="12"
            md="1"
          >
            <v-select
              v-model="filter.condition"
              append-icon=""
              outlined
              dense
              :items="conditionType"
              :rules="[required]"
              hide-details
            />
          </v-col>

          <!-- Field Name Input -->
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="filter.fieldInput"
              placeholder="Filter Field"
              outlined
              dense
              :items="attributes"
              :rules="[required]"
              item-text="name"
              return-object
              hide-details="auto"
            />
          </v-col>

          <!-- Operator Input -->
          <v-col
            v-if="filter.fieldInput"
            cols="12"
            md="2"
          >
            <v-select
              v-if="!filter.fieldInput.data_type"
              v-model="filter.operator"
              placeholder="Operator"
              outlined
              dense
              :items="attributes.find(el => el.field_name === filter.fieldInput.field_name).operator"
              item-text="name"
              item-value="value"
              hide-details="auto"
              :rules="[required]"
            />
            <v-select
              v-else
              v-model="filter.operator"
              placeholder="Operator"
              outlined
              dense
              :items="filter.fieldInput.data_type.operator"
              item-text="name"
              item-value="value"
              hide-details="auto"
              :rules="[required]"
            />
          </v-col>

          <!-- Value Input -->
          <v-col
            v-if="filter.fieldInput && !['IS SET', 'IS NOT SET'].includes(filter.operator)"
            cols="11"
            md="5"
          >
            <date-range-picker
              v-if="['updated_at', 'expected_close_date'].includes(filter.fieldInput.field_name)"
              :value="filter.value"
              @select="filter.value = {
                date_min: $event.startDate,
                date_max: $event.endDate,
              }"
            />
            <user-auto-suggest
              v-else-if="['assigned_to', 'created_by'].includes(filter.fieldInput.field_name)"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :multiple="filter.fieldInput.field_name === 'assigned_to'"
              :attach="false"
              :return-object="false"
              class="value-input"
            >
              <template
                v-if="filter.fieldInput.field_name === 'assigned_to'"
                #match
              >
                <v-select
                  v-model="filter.match_type"
                  outlined
                  dense
                  hide-details
                  :rules="[required]"
                  :items="matchType"
                />
              </template>
            </user-auto-suggest>
            <customer-auto-suggest
              v-else-if="filter.fieldInput.field_name === 'customer'"
              v-model="filter.value"
              :return-object="false"
              :attach="false"
              :label="filter.fieldInput.name"
              :multiple="false"
            />
            <v-select
              v-else-if="['status', 'priority'].includes(filter.fieldInput.field_name)"
              v-model="filter.value"
              :items="props[filter.fieldInput.field_name]"
              :label="`${filter.fieldInput.name} Job`"
              outlined
              item-text="name"
              item-value="id"
              dense
              chips
              small-chips
              hide-details="auto"
              :disabled="props[filter.fieldInput.field_name] && props[filter.fieldInput.field_name].length === 0"
            >
              <template #selection="{ item }">
                <v-chip
                  :color="item.color"
                  small
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-text-field
              v-else-if="filter.fieldInput.data_type.id === 1"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :placeholder="'Input ' + filter.fieldInput.name"
              :rules="[required]"
              outlined
              dense
              :attach="false"
              hide-details="auto"
              class="value-input"
            >
              <template
                v-if="filter.operator !== '>' && filter.operator !== '<'"
                #append-outer
              >
                <v-select
                  v-model="filter.match_type"
                  outlined
                  dense
                  :rules="[required]"
                  :items="matchType"
                />
              </template>
            </v-text-field>
            <v-text-field
              v-else-if="filter.fieldInput.data_type.id === 2"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :placeholder="'Input ' + filter.fieldInput.name"
              :rules="[required]"
              outlined
              dense
              hide-details="auto"
              type="number"
              class="value-input"
            />
            <DateTimePicker
              v-else-if="filter.fieldInput.data_type.id === 3"
              v-model="filter.value"
              :label="filter.fieldInput.name"
              :placeholder="filter.fieldInput.name"
              :rules="[required]"
            />
            <v-select
              v-else-if="filter.fieldInput.data_type.id === 4 || filter.fieldInput.data_type.id === 5"
              v-model="filter.value"
              :items="filter.fieldInput.options"
              :label="filter.fieldInput.name"
              :placeholder="filter.fieldInput.name"
              outlined
              dense
              :multiple="filter.fieldInput.data_type.id === 5"
              :chips="filter.fieldInput.data_type.id === 5"
              item-text="name"
              item-value="id"
              hide-details="auto"
              :rules="[required]"
              class="value-input"
            >
              <template
                v-if="filter.operator !== '>' && filter.operator !== '<' && filter.fieldInput.data_type.id === 5"
                #append-outer
              >
                <v-select
                  v-model="filter.match_type"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="[required]"
                  :items="matchType"
                />
              </template>
            </v-select>
          </v-col>

          <v-col
            v-if="index !== 0"
            cols="1"
            md="1"
            dense
            class="pr-0 pl-1 align-self-center"
          >
            <v-btn
              small
              icon
              plain
              @click="removeFilter(index)"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div class="d-flex flex-column flex-sm-row mt-5 pt-5">
        <v-btn
          class="mb-2 mb-sm-0 mr-sm-2"
          outlined
          @click="addFilter()"
        >
          Tambah Filter
        </v-btn>
        <v-btn
          class="mb-2 mb-sm-0 mr-sm-2 mr-sm-auto"
          outlined
          @click="addGroupFilter()"
        >
          Tambah Grup Filter
        </v-btn>
        <v-btn
          class="mb-2 mb-sm-0 mr-sm-2 ml-sm-auto"
          outlined
          @click="clearFilter()"
        >
          Reset Filter
        </v-btn>
        <v-btn
          color="primary"
          @click="filter"
        >
          Filter Job
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'
import UserAutoSuggest from '@/components/inputs/UserAutoSuggest.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
import { required } from '@core/utils/validation'
import store from '@/store'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    DateTimePicker,
    UserAutoSuggest,
    CustomerAutoSuggest,
    DateRangePicker,
  },
  props: {
    status: {
      type: Array,
      default: () => [],
    },
    priority: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state = { ...useFieldJob(['jobFilter']) }

    const jobFilterBuilder = ref()
    const jobFilter = ref([{}])
    const isOpen = ref(false)

    const matchType = ref(['ANY', 'ALL'])
    const conditionType = ref(['AND', 'OR'])
    const requiredOperator = [
      { name: 'Contains', value: 'IS' },
      { name: 'Does not contain', value: 'IS NOT' },
    ]
    const nullableOperator = [
      { name: 'Contains', value: 'IS' },
      { name: 'Does not contain', value: 'IS NOT' },
      { name: 'Is set', value: 'IS SET' },
      { name: 'Is not set', value: 'IS NOT SET' },
    ]
    const dateOperator = [
      { name: 'Between', value: 'BETWEEN' },
      { name: 'Not Between', value: 'NOT BETWEEN' },
      { name: 'Is set', value: 'IS SET' },
      { name: 'Is not set', value: 'IS NOT SET' },
    ]
    const attributes = ref([
      {
        name: 'Tanggal Ditutup',
        field_name: 'expected_close_date',
        operator: dateOperator,
      },
      {
        name: 'Tanggal Diupdate',
        field_name: 'updated_at',
        operator: dateOperator,
      },
      {
        name: 'Status',
        field_name: 'status',
        operator: requiredOperator,
      },
      {
        name: 'Prioritas',
        field_name: 'priority',
        operator: nullableOperator,
      },
      {
        name: 'Pelanggan',
        field_name: 'customer',
        operator: nullableOperator,
      },
      {
        name: 'Ditugaskan ke',
        field_name: 'assigned_to',
        operator: nullableOperator,
      },
      {
        name: 'Dibuat Oleh',
        field_name: 'created_by',
        operator: requiredOperator,
      },
    ])

    const show = customAttributesArray => {
      isOpen.value = true
      attributes.value.push(...customAttributesArray.map(ca => ca.custom_attribute))
      jobFilter.value = state.jobFilter.value.raw_filter.length ? state.jobFilter.value.raw_filter : [{ match_type: 'ANY', condition: 'AND' }]
    }

    const close = () => {
      isOpen.value = false
    }

    const filter = () => {
      if (jobFilterBuilder.value.validate()) {
        state.jobFilter.value.raw_filter = jobFilter.value
        state.jobFilter.value.custom_filter = jobFilter.value.filter(f => !f.fieldInput.field_name)
        state.jobFilter.value.status = jobFilter.value.filter(el => el.fieldInput.field_name === 'status')
        state.jobFilter.value.created_by = jobFilter.value.filter(el => el.fieldInput.field_name === 'created_by')
        state.jobFilter.value.priority = jobFilter.value.filter(el => el.fieldInput.field_name === 'priority')
        state.jobFilter.value.customer = jobFilter.value.filter(el => el.fieldInput.field_name === 'customer')
        state.jobFilter.value.assigned_to = jobFilter.value.filter(el => el.fieldInput.field_name === 'assigned_to')
        state.jobFilter.value.updated_at = jobFilter.value.filter(el => el.fieldInput.field_name === 'updated_at')
        state.jobFilter.value.expected_close_date = jobFilter.value.filter(el => el.fieldInput.field_name === 'expected_close_date')

        console.log('expected_close_date', state.jobFilter.value.expected_close_date)
        console.log('updated_at', state.jobFilter.value.updated_at)

        isOpen.value = false
        emit('close')
      }
    }

    const clearFilter = () => {
      store.dispatch('job/resetFilter')
      // state.jobFilter.value.count = 0

      jobFilter.value = [{}]
      emit('close')
      isOpen.value = false
      jobFilterBuilder.value.reset()
    }

    const removeFilter = index => {
      jobFilter.value.splice(index, 1)
    }

    const addFilter = () => {
      jobFilter.value.push({ match_type: 'ANY', condition: 'AND' })
    }

    const addGroupFilter = () => {
      jobFilter.value.push({ match_type: 'ANY', condition: 'AND', group: [] })
    }

    return {
      state,
      props,
      jobFilterBuilder,
      attributes,
      isOpen,
      jobFilter,
      matchType,
      conditionType,

      required,
      show,
      close,
      filter,
      addFilter,
      clearFilter,
      removeFilter,
      addGroupFilter,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>
  .value-input >>> .v-input__append-outer {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-width: 100px;
  }
</style>
