<template>
  <div>
    <v-chip
      small
      class="mr-2 my-1"
      :color="data.status.color"
    >
      {{ data.status.name }}
    </v-chip>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
