<template>
  <div>
    <router-link :to="{ name: 'user-detail', params: { id: data.created_by.id } }">
      <span class="text-subtitle-2">{{ data.created_by.name }}</span>
    </router-link>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
