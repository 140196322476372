<template>
  <div v-if="value(data)">
    <v-chip
      v-for="option_id in JSON.parse(value(data))"
      :key="option_id"
      class="mr-1 my-1"
      small
    >
      {{ customAttribute(data).options.find(el => el.id === option_id).name }}
    </v-chip>
  </div>
  <span v-else />
</template>
<script>
import { reactive } from '@vue/composition-api'

export default {
  props: {
    customAttributes: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const data = reactive({})

    const attributeId = rowData => rowData.column.field.split('.', 2)[1]
    const customAttribute = rowData => props.customAttributes.find(el => el.custom_attribute.id === Number(attributeId(rowData))).custom_attribute
    const value = rowData => {
      if (
        rowData.custom_attribute_values === null
        || rowData.custom_attribute_values[attributeId(rowData)] === undefined
        || rowData.custom_attribute_values[attributeId(rowData)] === null
      ) {
        return false
      }

      return rowData.custom_attribute_values[attributeId(rowData)]
    }

    return {
      data,

      customAttribute,
      value,
    }
  },
}
</script>
