<template>
  <div class="d-flex align-center">
    <limit-elements
      v-if="data.assignedTo.length"
      :elements="data.assignedTo"
      :limit="3"
      class="v-avatar-group mr-auto"
      :class="rootThemeClasses"
    >
      <template #default="el">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="36"
              class="primary"
              v-on="on"
            >
              <v-img
                v-if="el.data.user.photo"
                :src="el.data.user.photo"
              />
              <span
                v-else
                class="white--text"
              >
                {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
              </span>
            </v-avatar>
          </template>
          <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
        </v-tooltip>
      </template>
      <template #others="el">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="36"
              class="primary mb-1"
              v-on="on"
            >
              <v-img
                v-if="el.data.user.photo"
                :src="el.data.user.photo"
              />
              <span
                v-else
                class="white--text"
              >
                {{ avatarText(`${el.data.user.first_name} ${el.data.user.last_name}`) }}
              </span>
            </v-avatar>
          </template>
          <span>{{ el.data.user.first_name }} {{ el.data.user.last_name }}</span>
        </v-tooltip>
      </template>
      <template #others-activator="el">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              size="36"
              color="white"
              class="primary--text text-caption"
              v-on="on"
            >
              <span>+{{ el.data.limit }}</span>
            </v-avatar>
          </template>
          <span>Lihat User Lain</span>
        </v-tooltip>
      </template>
    </limit-elements>
    <v-menu
      :close-on-content-click="false"
      left
    >
      <template v-slot:activator="menu">
        <v-tooltip
          top
        >
          <template v-slot:activator="tooltip">
            <v-btn
              v-bind="{ ...menu.attrs, ...tooltip.attrs }"
              text
              outlined
              small
              rounded
              color="primary"
              v-on="{ ...menu.on, ...tooltip.on }"
              @click.stop
            >
              <v-icon
                size="18"
              >
                {{ icons.mdiAccountPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>Tugaskan ke Pengguna</span>
        </v-tooltip>
      </template>

      <assign-object
        :job-id="data.id"
        :data="data.assignedTo"
        @success="$emit('refetch')"
      />
    </v-menu>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import LimitElements from '@/components/misc/LimitElements.vue'
import AssignObject from '@/components/inputs/AssignObject.vue'
import { mdiAccountPlus } from '@mdi/js'
import useVuetify from '@core/utils/vuetify'

export default {
  components: {
    LimitElements,
    AssignObject,
  },
  setup() {
    const { rootThemeClasses } = useVuetify()
    const data = reactive({})

    return {
      data,
      rootThemeClasses,

      avatarText,

      icons: {
        mdiAccountPlus,
      },
    }
  },
}
</script>
