<template>
  <v-card
    width="250px"
    class="px-3 py-2"
    style="overflow: hidden"
  >
    <v-select
      v-model="state.jobTypeFilter.value.sort"
      outlined
      flat
      dense
      class="my-2"
      item-text="text"
      item-value="id"
      :items="sortOptions"
      hide-details="auto"
      label="Sort"
      return-object
    >
      <template v-slot:item="{ item }">
        <div class="d-flex align-center justify-space-between flex-grow-1">
          <div class="font-weight-bold">
            <v-icon
              class="me-1"
              size="26"
            >
              {{ item.icon }}
            </v-icon>
            <span class="font-weight-semibold"> {{ item.text }}</span>
          </div>
        </div>
      </template>
    </v-select>
    <v-select
      v-model="state.jobTypeFilter.value.my_jobType"
      outlined
      flat
      dense
      class="my-2"
      item-text="text"
      item-value="id"
      :items="filterOwnerOptions"
      hide-details="auto"
      label="Owner"
      return-object
    />
    <v-select
      v-model="state.jobTypeFilter.value.show_archived"
      outlined
      flat
      dense
      class="my-2"
      item-text="text"
      item-value="id"
      :items="filterArchiveOptions"
      hide-details="auto"
      label="Show Archive"
      return-object
    />
    <v-row class="pb-2">
      <v-col
        cols="6"
        class="pe-1"
      >
        <v-btn
          block
          color="primary"
          outlined
          small
          @click="resetJobTypeFilter"
        >
          Reset
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="ps-1"
      >
        <v-btn
          block
          color="primary"
          small
          @click="$emit('onSearchJobType')"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ref } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'
import {
  mdiSortAlphabeticalAscending, mdiSortAlphabeticalDescending, mdiSortClockAscending, mdiSortClockDescending,
} from '@mdi/js'
import store from '@/store'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  setup() {
    const state = {
      ...useFieldJob(['jobTypeFilter']),
    }

    const resetJobTypeFilter = () => {
      console.log('tes')
      store.dispatch('job/resetJobTypeFilter')
    }

    const sortOptions = ref([
      {
        id: 1,
        text: 'Nama (A-Z)',
        value: 'name',
        sortType: 'ASC',
        icon: mdiSortAlphabeticalAscending,
      },
      {
        id: 2,
        text: 'Nama (Z-A)',
        value: 'name',
        sortType: 'DESC',
        icon: mdiSortAlphabeticalDescending,
      },
      {
        id: 3,
        text: 'Tanggal Dibuat (Terbaru)',
        value: 'created_at',
        sortType: 'ASC',
        icon: mdiSortClockAscending,
      },
      {
        id: 4,
        text: 'Tanggal Dibuat (Terlama)',
        value: 'created_at',
        sortType: 'DESC',
        icon: mdiSortClockDescending,
      },
    ])

    const filterOwnerOptions = ref([
      {
        id: 1,
        text: 'All',
        value: null,
      },
      {
        id: 2,
        text: 'My Job Type',
        value: true,
      },
      {
        id: 3,
        text: "Other's Job Type",
        value: false,
      },
    ])

    const filterArchiveOptions = ref([
      {
        id: 1,
        text: 'Show',
        value: true,
      },
      {
        id: 2,
        text: 'Hide',
        value: false,
      },
    ])

    return {
      sortOptions,
      state,
      filterOwnerOptions,
      filterArchiveOptions,
      resetJobTypeFilter,
    }
  },
}
</script>
